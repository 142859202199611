import React, { useState, useEffect, useContext, useRef } from "react"
import { useEffectOnce } from "react-use"

/* Import Global Context*/
import LoadingContext from "~context/loading"

/* Import Global Hooks*/
import usePlayheadPosition from "~hooks/usePlayheadPosition"
import useAudioContext from "~hooks/useAudioContext"
import useAudioPermission from "~hooks/useAudioPermission"

/* Import Local Style(s) */
import * as styles from "./audio.module.scss"

const AudioLoop = ({ mp3, ogg, opus }) => {
  /* Context */
  const { setAudioLoopLoading } = useContext(LoadingContext)
  /* Hooks */
  const audioContext = useAudioContext()
  const playheadPosition = usePlayheadPosition()
  const audioPermission = useAudioPermission()
  /* References */
  const audioElementReference = useRef(null)
  /* State */
  const [isPlaying, setIsPlaying] = useState(false)
  const [canPlayThrough, setCanPlayThrough] = useState(false)

  useEffectOnce(() => {
    if (audioElementReference && audioElementReference.current) {
      // listen for when audio file is fully loaded
      audioElementReference.current.addEventListener("canplaythrough", () => {
        setCanPlayThrough(true)
      })

      // listen for when audio file is playing
      audioElementReference.current.addEventListener("play", () => {
        gainNode.gain.linearRampToValueAtTime(1.0, audioContext.currentTime + 8)
      })

      // Create a MediaElementAudioSourceNode
      // Feed the HTMLMediaElement into it
      const source = audioContext.createMediaElementSource(
        audioElementReference.current
      )

      // Create a gain node
      const gainNode = audioContext.createGain()
      gainNode.gain.setValueAtTime(0, audioContext.currentTime)

      // connect the AudioBufferSourceNode to the gainNode
      source.connect(gainNode)
      gainNode.connect(audioContext.destination)

      /* Loading and Configuration Complete */
      setAudioLoopLoading(false)
    }
  }, [])

  useEffect(() => {
    const handlePlay = async () => {
      audioElementReference.current.currentTime = playheadPosition
      try {
        await audioElementReference.current.play()
        setIsPlaying(true)
      } catch (err) {
        console.log("Error playing audio: ", err)
      }
    }
    if (audioPermission && !isPlaying) {
      handlePlay()
    }
  }, [audioPermission, isPlaying, playheadPosition])

  return (
    <>
      <audio
        className={`audio ${styles.audio}`}
        ref={audioElementReference}
        loop
      >
        <source src={opus} type="audio/ogg; codecs=opus" />
        <source src={ogg} type="audio/ogg; codecs=vorbis" />
        <source src={mp3} type="audio/mpeg" />
      </audio>
      {/* <button
        className={`play-button ${styles.playButton} ${
          audioPermission ? styles.playButtonIsPlaying : null
        }`}
        disable={(!canPlayThrough).toString()}
      >
        Enable Audio
      </button> */}
    </>
  )
}

export default AudioLoop
