import React from "react"

/* Import Global Component(s) */
import Header from "~components/header/header"
import Seo from "~components/seo/seo"
import Subtitle from "~components/subtitle/subtitle"
import AudioLoop from "~components/audio/audio"
import Console from "~components/console/console"
import Loading from "~components/loading/loading"
import Footer from "~components/footer/footer"

/* Import Scene Specific Components */
import CompositionsManager from "~components/compositionsManager/compositionsManager"
import Harpy from "~components/compositionsManager/components/harpy"
import HarpyBlue from "~components/compositionsManager/components/harpyBlue"
import NervousSystem from "~components/compositionsManager/components/nervousSystem"
import NervousSystemLayingDown from "~components/compositionsManager/components/nervoLaying"
import Particles from "~components/compositionsManager/components/particles"
import TechnologyTree from "~sceneComponents/technologyTree/technologyTree"
import GraphVideo from "~sceneComponents/graphVideo/graphVideo"
import HarpyAndNervousSystemVideo from "~sceneComponents/harpyAndNervousSystemVideo/harpyAndNervousSystemVideo"
import PassthroughHandsVideo from "~sceneComponents/passthroughHandsVideo/passthroughHandsVideo"
import DroneVideo from "~sceneComponents/droneVideo/droneVideo"
import RainVideo from "~sceneComponents/rainVideo/rainVideo"

/* Placeholders */
import BurningBushPlaceholder from "~sceneComponents/burningBushPlaceholder/burningBushPlaceholder"

/* Import Local Assets*/
/* Compressed Website Loop in MP3, OGG, OPUS */
import theMawOfWebsiteAudioMp3Src from "~assets/audio/the-maw-of-desktop-audio-compressed-september-12.mp3"
import theMawOfWebsiteAudioOggSrc from "~assets/audio/the-maw-of-desktop-audio-compressed-september-12.ogg"
import theMawOfWebsiteAudioOpusSrc from "~assets/audio/the-maw-of-desktop-audio-compressed-september-12.opus"

/* Import Local Style(s) */
import * as styles from "./index.module.scss"

const IndexPage = ({ whitneyHtmlRef }) => {
  return (
    <div className={`index ${styles.index}`}>
      {/* Loading */}
      <Loading />
      {/* Header and Play Position */}
      <Header />
      {/* Looping Audio Throughout */}
      <AudioLoop
        mp3={theMawOfWebsiteAudioMp3Src}
        ogg={theMawOfWebsiteAudioOggSrc}
        opus={theMawOfWebsiteAudioOpusSrc}
      />
      {/* Scene Elements  */}
      <Console startTime={1} endTime={19} whitneyHtmlRef={whitneyHtmlRef} />
      <GraphVideo startTime={1} endTime={10} />
      <PassthroughHandsVideo startTime={8} endTime={13} />
      <TechnologyTree startTime={10} endTime={30} />
      <HarpyAndNervousSystemVideo startTime={18} endTime={30} />
      <BurningBushPlaceholder startTime={34} endTime={53} />
      <DroneVideo startTime={67} endTime={77} />
      <RainVideo startTime={90} endTime={93} />
      {/* NYIO Compositions */}
      <CompositionsManager>
        <Harpy startTime={30} endTime={67} />
        <HarpyBlue startTime={67} endTime={85} />
        <Particles startTime={85} endTime={88} />
        <NervousSystem startTime={88} endTime={90} />
        <Particles startTime={90} endTime={92} />
        <Harpy startTime={92} endTime={100} />
        <Particles startTime={100} endTime={103} />
        <NervousSystemLayingDown startTime={103} endTime={113} />
        <Particles startTime={113} endTime={115} />
        <Harpy startTime={115} endTime={134} />
      </CompositionsManager>
      <HarpyAndNervousSystemVideo startTime={125} endTime={134} />
      {/* <Console startTime={109} endTime={134} whitneyHtmlRef={whitneyHtmlRef} /> */}
      {/* Black Screen */}
      {/* <BlackScreen startTime={85} endTime={109} /> */}
      <Footer>
        <Subtitle startTime={1} endTime={18} copy="You'll Tell me now —" />
        <Subtitle startTime={57} endTime={67} copy="Ask your parents" />
      </Footer>
    </div>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
