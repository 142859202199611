import React from "react"

/* Import Global Components */
import VideoPlayer from "~components/videoPlayer/videoPlayer"

/* Import Global Higher Order Components */
import withTimeline from "~higherOrderComponents/withTimeline"

/* Import Global Assets */
import BurningBushPlaceholderSrc from "~assets/video/burning-bush-placeholder-compressed.webm"

/* Import Local Styles */
import * as styles from "./burning-bush-placeholder.module.scss"

const BurningBushPlaceholder = () => {
  return (
    <div className={`burning-bush ${styles.burningBush}`}>
      <VideoPlayer
        url={BurningBushPlaceholderSrc}
        loop={true}
        reactPlayerWrapperClassName={styles.reactPlayerWrapperClassNameOverride}
        reactPlayerClassName={styles.reactPlayerClassNameOverride}
      />
    </div>
  )
}

const BurningBushPlaceholderWithTimeline = withTimeline(BurningBushPlaceholder)

export default BurningBushPlaceholderWithTimeline
