import React from "react"
import { useEffectOnce } from "react-use"

/* Import Global Higher Order Components */
import withTimeline from "~higherOrderComponents/withTimeline"

/* Import Global Hooks */
import useAudioSampler from "~hooks/useAudioSampler"

/* Import Local Styles */
import * as styles from "./subtitle.module.scss"

const Subtitle = ({ copy, audioSample }) => {
  const { playSample } = useAudioSampler()

  useEffectOnce(() => {
    if (audioSample) {
      playSample(audioSample)
    }
  }, [])

  return <p className={`subtitle--copy ${styles.subtitleCopy}`}>{copy}</p>
}

const SubtitleWithTimeline = withTimeline(Subtitle)

export default SubtitleWithTimeline
