import React from "react"

/* Import Global Components */
import VideoPlayer from "~components/videoPlayer/videoPlayer"

/* Import Global Higher Order Components */
import withTimeline from "~higherOrderComponents/withTimeline"

/* Import Global Assets */
import droneVideoSrc from "~assets/video/drone-compressed.webm"

/* Import Local Styles */
import * as styles from "./drone-video.module.scss"

const DroneVideo = ({
  /* Component Props */
  url,
}) => {
  return (
    <div className={`drone ${styles.drone}`}>
      <div
        style={{
          width: "100%",
        }}
      >
        <VideoPlayer url={droneVideoSrc} loop={true} />
      </div>
    </div>
  )
}

const DroneVideoWithTimeline = withTimeline(DroneVideo)

export default DroneVideoWithTimeline
