import React from "react"

/* Import Global Components */
import VideoPlayer from "~components/videoPlayer/videoPlayer"

/* Import Global Higher Order Components */
import withTimeline from "~higherOrderComponents/withTimeline"

/* Import Global Assets */
import PassthroughHandsVideoSrc from "~assets/video/passthrough-hands-compressed.webm"

/* Import Local Styles */
import * as styles from "./passthrough-hands.module.scss"

const PassthroughHandsVideo = ({
  /* Component Props */
  url,
}) => {
  return (
    <div className={`passthrough-hands ${styles.passthroughHands}`}>
      <div
        style={{
          width: "100%",
        }}
      >
        <VideoPlayer url={PassthroughHandsVideoSrc} loop={true} />
      </div>
    </div>
  )
}

const PassthroughHandsVideoWithTimeline = withTimeline(PassthroughHandsVideo)

export default PassthroughHandsVideoWithTimeline
