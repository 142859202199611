import React from "react"

/* Import Global Components */
import VideoPlayer from "~components/videoPlayer/videoPlayer"

/* Import Global Higher Order Components */
import withTimeline from "~higherOrderComponents/withTimeline"

/* Import Global Assets */
import GraphVideoSrc from "~assets/video/graph-compressed.webm"

/* Import Local Styles */
import * as styles from "./graph-video.module.scss"

const GraphVideo = ({
  /* Component Props */
  url,
}) => {
  return (
    <div className={`graph-video ${styles.graphVideo}`}>
      <VideoPlayer url={GraphVideoSrc} loop={true} />
    </div>
  )
}

const GraphVideoWithTimeline = withTimeline(GraphVideo)

export default GraphVideoWithTimeline
