import React from "react"

/* Import Global Components */
import VideoPlayer from "~components/videoPlayer/videoPlayer"

/* Import Global Higher Order Components */
import withTimeline from "~higherOrderComponents/withTimeline"

/* Import Global Assets */
import HarpyAndNervousSystemVideoSrc from "~assets/video/RachelRossin_Mawof2_Presents-compressed.webm"

/* Import Local Styles */
import * as styles from "./harpy-and-nervous-system.module.scss"

const HarpyAndNervousSystemVideo = ({
  /* Component Props */
  url,
}) => {
  return (
    <div className={`harpy-and-nervous-system ${styles.harpyAndNervousSystem}`}>
      <div
        style={{
          width: "100%",
        }}
      >
        <VideoPlayer url={HarpyAndNervousSystemVideoSrc} loop={true} />
      </div>
    </div>
  )
}

const HarpyAndNervousSystemVideoWithTimeline = withTimeline(
  HarpyAndNervousSystemVideo
)

export default HarpyAndNervousSystemVideoWithTimeline
