import { useContext } from "react"

import AudioContext from "~context/audioContext"

const useAudioContext = () => {
  const { audioContext } = useContext(AudioContext)

  return audioContext
}

export default useAudioContext
