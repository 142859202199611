import React from "react"

/* Import Global Components */
import VideoPlayer from "~components/videoPlayer/videoPlayer"

/* Import Global Higher Order Components */
import withTimeline from "~higherOrderComponents/withTimeline"

/* Import Global Assets */
import RainVideoSrc from "~assets/video/rain-compressed.webm"

/* Import Local Styles */
import * as styles from "./rain-video.module.scss"

const RainVideo = ({
  /* Component Props */
  url,
}) => {
  return (
    <div className={`rain ${styles.rain}`}>
      <div
        style={{
          width: "50vw",
        }}
      >
        <VideoPlayer url={RainVideoSrc} loop={true} />
      </div>
    </div>
  )
}

const RainVideoWithTimeline = withTimeline(RainVideo)

export default RainVideoWithTimeline
