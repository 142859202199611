import React, { useState, useEffect, useRef } from "react"
import ReactPlayer from "react-player"

/* Import Local Styles */
import * as styles from "./video-player.module.scss"

const VideoPlayer = ({
  /* Component Props */
  url,
  /* Timeline Props */
  startTime,
  playheadPosition,
  /* Player Configuration */
  loop = false,
  controls = false,
  volume = 0,
  muted = true,
  playsinline = true,
  pip = false,
  /* Overwrite Styles */
  reactPlayerWrapperClassName = null,
  reactPlayerClassName = null,
}) => {
  const [isPlaying] = useState(true)
  const [offset] = useState(playheadPosition - startTime)
  const reactPlayerRef = useRef(null)

  useEffect(() => {
    if (reactPlayerRef && reactPlayerRef.current) {
      reactPlayerRef.current.seekTo(offset, "seconds")
    }
  }, [])

  return (
    <div
      className={`react-player-wrapper ${styles.reactPlayerWrapper} ${reactPlayerWrapperClassName}`}
    >
      <ReactPlayer
        ref={reactPlayerRef}
        className={`react-player ${styles.reactPlayer} ${reactPlayerClassName}`}
        url={url}
        playing={isPlaying}
        loop={loop}
        controls={controls}
        volume={volume}
        muted={muted}
        width="100%"
        height="100%"
        playsinline={playsinline}
        pip={pip}
      />
    </div>
  )
}

export default VideoPlayer
