import { useState, useContext } from "react"
import { useEffectOnce } from "react-use"

import AudioContext from "~context/audioContext"

// const isBrowser = typeof window !== "undefined"

const useAudioPermission = () => {
  const { audioContext } = useContext(AudioContext)
  const [audioPermission, setAudioPermission] = useState(
    audioContext ? audioContext.state === "running" : false
  )

  useEffectOnce(() => {
    audioContext.onstatechange = () => {
      if (audioContext.state === "running") {
        setAudioPermission(true)
      } else {
        setAudioPermission(false)
      }
    }
  }, [])

  return audioPermission
}

export default useAudioPermission
